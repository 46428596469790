import { alpha, darken } from '@theme-ui/color'

const heading = {
  color: 'primary',
  fontWeight: 'lighter'
}

const theme = {
  fonts: {
    body: 'poppins, sans-serif',
    heading: 'caviar dreams, sans-serif'
  },
  lineHeights: {
    body: 1.4,
    paragraph: 1.2
  },
  colors: {
    text: '#564a44',
    background: '#ffffff',
    primary: '#ec605a',
    secondary: '#ef7e51',
    muted: alpha('primary', 0.5),
    disabled: darken('text', 0.5)
  },
  sizes: {
    container: 1200
  },
  text: {
    heading: {
      color: 'primary',
      fontWeight: 'lighter'
    },
    pageTitle: {
      variant: 'text.heading',
      fontSize: [6, 8]
    },
    paragraph: {
      lineHeight: 'paragraph'
    }
  },
  links: {
    menu: {
      color: 'primary',
      textDecoration: 'none'
    }
  },
  badges: {
    primary: {
      backgroundColor: alpha('primary', 0.7),
      textTransform: 'uppercase',
      fontSize: 2,
      px: 2
    }
  },
  forms: {
    select: {
      pr: 28,
      width: [null, 'inherit'],
    }
  },
  styles: {
    a: {
      color: 'secondary'
    },
    h1: heading,
    h2: heading,
    h3: {
      ...heading,
      fontSize: 4
    }
  }
}

// defaultBreakpoints : 40, 52, 64

export default theme;
