import { useStaticQuery, graphql } from "gatsby"

export function useSiteMetadata() {
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            siteUrl
            contact {
              email
            }
            categories {
              id
              name
            }
          }
        }
      }
    `
  )
  return siteMetadata
}
